#root {
  background-color: grey;
}
#root .container {
  background-color: white;
  padding-top: 30px;
  padding-bottom: 30px;
}
.btn {
  margin-right: 5px;
}
#alert_copy_to_clipboard {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 10;
}

